// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RequestQuestionTitle-module__questionTitleWrapper__WDMwO .anticon {
  color: #e9b825;
  margin-right: 8px;
  font-size: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/components/request/RequestQuestionTitle.module.less"],"names":[],"mappings":"AACE;EACE,cAAA;EACA,iBAAA;EACA,eAAA;AAAJ","sourcesContent":[".questionTitleWrapper {\n  & :global(.anticon) {\n    color: #e9b825;\n    margin-right: 8px;\n    font-size: 18px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"questionTitleWrapper": `RequestQuestionTitle-module__questionTitleWrapper__WDMwO`
};
export default ___CSS_LOADER_EXPORT___;
