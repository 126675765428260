// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MiniPlayground-module__wrapper__PAHQ2 {
  background: #f7f7f7;
  padding: 16px;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.MiniPlayground-module__header__M9bXA {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 8px;
}
.MiniPlayground-module__runInfo__YgEFa {
  font-size: 12px;
  color: #00000073;
}
.MiniPlayground-module__sentenceInfo__MXfmZ {
  font-size: 14px;
  font-family: 'Source Code Pro';
}
.MiniPlayground-module__closeIcon__nYOcx svg {
  color: #00000040;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.MiniPlayground-module__content__ELvcb {
  min-height: 400px;
  width: 100%;
  background: white;
  position: relative;
  overflow: hidden;
}
.MiniPlayground-module__footer__lF0cU {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 12px;
}
.MiniPlayground-module__contentAnswerContainer__shHKG {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 11;
  background-color: white;
  min-height: 390px;
  overflow: hidden;
}
.MiniPlayground-module__contentAnswer__dpmeO {
  padding: 16px 6px 16px 16px;
  overflow: scroll;
}
.MiniPlayground-module__contentAnswerActions__jxT7M {
  padding: 16px 6px 16px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/MiniPlayground/MiniPlayground.module.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,aAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;AACF;AAEA;EACE,WAAA;EACA,aAAA;EACA,8BAAA;EACA,uBAAA;EACA,mBAAA;AAAF;AAMA;EACE,eAAA;EACA,gBAAA;AAJF;AAOA;EACE,eAAA;EACA,8BAAA;AALF;AAQA;EACE,gBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;AANF;AASA;EACE,iBAAA;EACA,WAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;AAPF;AAUA;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,iBAAA;AARF;AAWA;EACE,kBAAA;EACA,WAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,uBAAA;EACA,iBAAA;EACA,gBAAA;AATF;AAYA;EACE,2BAAA;EACA,gBAAA;AAVF;AAaA;EACE,2BAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AAXF","sourcesContent":[".wrapper {\n  background: #f7f7f7;\n  padding: 16px;\n  border-radius: 6px;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n}\n\n.header {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  padding-bottom: 8px;\n}\n\n.headerInfo {\n}\n\n.runInfo {\n  font-size: 12px;\n  color: #00000073;\n}\n\n.sentenceInfo {\n  font-size: 14px;\n  font-family: 'Source Code Pro';\n}\n\n.closeIcon svg {\n  color: #00000040;\n  width: 20px;\n  height: 20px;\n  cursor: pointer;\n}\n\n.content {\n  min-height: 400px;\n  width: 100%;\n  background: white;\n  position: relative;\n  overflow: hidden;\n}\n\n.footer {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  padding-top: 12px;\n}\n\n.contentAnswerContainer {\n  position: absolute;\n  width: 100%;\n  top: 0;\n  left: 0;\n  z-index: 11;\n  background-color: white;\n  min-height: 390px;\n  overflow: hidden;\n}\n\n.contentAnswer {\n  padding: 16px 6px 16px 16px;\n  overflow: scroll;\n}\n\n.contentAnswerActions {\n  padding: 16px 6px 16px 16px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `MiniPlayground-module__wrapper__PAHQ2`,
	"header": `MiniPlayground-module__header__M9bXA`,
	"runInfo": `MiniPlayground-module__runInfo__YgEFa`,
	"sentenceInfo": `MiniPlayground-module__sentenceInfo__MXfmZ`,
	"closeIcon": `MiniPlayground-module__closeIcon__nYOcx`,
	"content": `MiniPlayground-module__content__ELvcb`,
	"footer": `MiniPlayground-module__footer__lF0cU`,
	"contentAnswerContainer": `MiniPlayground-module__contentAnswerContainer__shHKG`,
	"contentAnswer": `MiniPlayground-module__contentAnswer__dpmeO`,
	"contentAnswerActions": `MiniPlayground-module__contentAnswerActions__jxT7M`
};
export default ___CSS_LOADER_EXPORT___;
