// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RequestMethodLearning-module__requestMethodLearning__kg74X {
  margin-top: 40px;
}
.RequestMethodLearning-module__titleWrapper__sf4nY {
  margin-bottom: 12px;
}
.RequestMethodLearning-module__title__G17uF {
  color: var(--Neutral-8, #666680);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.28px;
}
.RequestMethodLearning-module__titleIcon__xAHqA {
  font-size: 16px;
}
.RequestMethodLearning-module__select__tyGO_ {
  width: 100% !important;
  margin-top: 12px;
  margin-bottom: 12px;
}
.RequestMethodLearning-module__input__ezWv2 {
  font-size: 14px;
}
.RequestMethodLearning-module__content__abhDw {
  margin-top: 20px;
}
.RequestMethodLearning-module__methodUsedTitle__PkJ_K {
  color: var(--Neutral-8, #666680);
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.28px;
}
.RequestMethodLearning-module__bookIcon__KGBwj {
  color: #666680;
  font-size: 16px;
}
.RequestMethodLearning-module__methodBody__LguYw {
  border-radius: 4px;
  border: 1px solid var(--Neutral-4, #c2c2d0);
  background: #fff;
  padding: 8px;
  cursor: pointer;
  margin-top: 8px;
  color: var(--Neutral-8, #666680);
  font-family: 'Source Code Pro';
  font-size: 12px;
  font-weight: 500;
  min-height: 50px;
}
.RequestMethodLearning-module__methodBodyFailed__C6ODg {
  border-color: #faad14;
}
.RequestMethodLearning-module__methodBodyLoading__yDr_O {
  cursor: progress;
}
.RequestMethodLearning-module__resetButton__I6GTt {
  margin-top: 20px;
  margin-bottom: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/components/request/LearningMethod/RequestMethodLearning.module.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAEA;EACE,mBAAA;AAAF;AAGA;EACE,gCAAA;EACA,eAAA;EACA,gBAAA;EACA,sBAAA;AADF;AAIA;EACE,eAAA;AAFF;AAKA;EACE,sBAAA;EACA,gBAAA;EACA,mBAAA;AAHF;AAMA;EACE,eAAA;AAJF;AAOA;EACE,gBAAA;AALF;AAQA;EACE,gCAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,sBAAA;AANF;AASA;EACE,cAAA;EACA,eAAA;AAPF;AAUA;EACE,kBAAA;EACA,2CAAA;EACA,gBAAA;EACA,YAAA;EACA,eAAA;EACA,eAAA;EACA,gCAAA;EACA,8BAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AARF;AAWA;EACE,qBAAA;AATF;AAYA;EACE,gBAAA;AAVF;AAaA;EACE,gBAAA;EACA,mBAAA;AAXF","sourcesContent":[".requestMethodLearning {\n  margin-top: 40px;\n}\n\n.titleWrapper {\n  margin-bottom: 12px;\n}\n\n.title {\n  color: var(--Neutral-8, #666680);\n  font-size: 16px;\n  font-weight: 600;\n  letter-spacing: 0.28px;\n}\n\n.titleIcon {\n  font-size: 16px;\n}\n\n.select {\n  width: 100% !important;\n  margin-top: 12px;\n  margin-bottom: 12px;\n}\n\n.input {\n  font-size: 14px;\n}\n\n.content {\n  margin-top: 20px;\n}\n\n.methodUsedTitle {\n  color: var(--Neutral-8, #666680);\n  font-family: Nunito;\n  font-size: 14px;\n  font-weight: 400;\n  letter-spacing: 0.28px;\n}\n\n.bookIcon {\n  color: #666680;\n  font-size: 16px;\n}\n\n.methodBody {\n  border-radius: 4px;\n  border: 1px solid var(--Neutral-4, #c2c2d0);\n  background: #fff;\n  padding: 8px;\n  cursor: pointer;\n  margin-top: 8px;\n  color: var(--Neutral-8, #666680);\n  font-family: 'Source Code Pro';\n  font-size: 12px;\n  font-weight: 500;\n  min-height: 50px;\n}\n\n.methodBodyFailed {\n  border-color: #faad14;\n}\n\n.methodBodyLoading {\n  cursor: progress;\n}\n\n.resetButton {\n  margin-top: 20px;\n  margin-bottom: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"requestMethodLearning": `RequestMethodLearning-module__requestMethodLearning__kg74X`,
	"titleWrapper": `RequestMethodLearning-module__titleWrapper__sf4nY`,
	"title": `RequestMethodLearning-module__title__G17uF`,
	"titleIcon": `RequestMethodLearning-module__titleIcon__xAHqA`,
	"select": `RequestMethodLearning-module__select__tyGO_`,
	"input": `RequestMethodLearning-module__input__ezWv2`,
	"content": `RequestMethodLearning-module__content__abhDw`,
	"methodUsedTitle": `RequestMethodLearning-module__methodUsedTitle__PkJ_K`,
	"bookIcon": `RequestMethodLearning-module__bookIcon__KGBwj`,
	"methodBody": `RequestMethodLearning-module__methodBody__LguYw`,
	"methodBodyFailed": `RequestMethodLearning-module__methodBodyFailed__C6ODg`,
	"methodBodyLoading": `RequestMethodLearning-module__methodBodyLoading__yDr_O`,
	"resetButton": `RequestMethodLearning-module__resetButton__I6GTt`
};
export default ___CSS_LOADER_EXPORT___;
