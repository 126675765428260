// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MiniPlaygroundPopup-module__miniPlaygroundPopup__rdcRh .ant-modal-content {
  padding: 0;
}
.MiniPlaygroundPopup-module__miniPlaygroundPopup__rdcRh .ant-modal-close {
  display: none;
}
.MiniPlaygroundPopup-module__miniPlaygroundPopup__rdcRh .editorErrorLineLast {
  background-color: inherit !important;
}
`, "",{"version":3,"sources":["webpack://./src/popups/MiniPlaygroundPopup.module.less"],"names":[],"mappings":"AACE;EACE,UAAA;AAAJ;AAGE;EACE,aAAA;AADJ;AALA;EAUI,oCAAA;AAFJ","sourcesContent":[".miniPlaygroundPopup {\n  & :global(.ant-modal-content) {\n    padding: 0;\n  }\n\n  & :global(.ant-modal-close) {\n    display: none;\n  }\n\n  :global(.editorErrorLineLast) {\n    background-color: inherit !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"miniPlaygroundPopup": `MiniPlaygroundPopup-module__miniPlaygroundPopup__rdcRh`
};
export default ___CSS_LOADER_EXPORT___;
