// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WorkerStatusInfo-module__statusText__Qh4sD {
  font-size: 12px;
  line-height: 32px;
  margin-left: 10px;
  color: #748f9e;
}
.WorkerStatusInfo-module__loader__A7Rhk,
.WorkerStatusInfo-module__loader__A7Rhk:before,
.WorkerStatusInfo-module__loader__A7Rhk:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: WorkerStatusInfo-module__bblFadInOut__aKBQc 1.8s infinite ease-in-out;
}
.WorkerStatusInfo-module__loader__A7Rhk {
  color: #661eff;
  font-size: 7px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}
.WorkerStatusInfo-module__loader__A7Rhk:before,
.WorkerStatusInfo-module__loader__A7Rhk:after {
  content: '';
  position: absolute;
  top: 0;
}
.WorkerStatusInfo-module__loader__A7Rhk:before {
  left: -3.5em;
  animation-delay: -0.32s;
}
.WorkerStatusInfo-module__loader__A7Rhk:after {
  left: 3.5em;
}
@keyframes WorkerStatusInfo-module__bblFadInOut__aKBQc {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/PlaygroundV3/components/WorkerStatusInfo/WorkerStatusInfo.module.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,cAAA;AACF;AAEA;;;EAGE,kBAAA;EACA,YAAA;EACA,aAAA;EACA,yBAAA;EACA,gFAAA;AAAF;AAEA;EACE,cAAA;EACA,cAAA;EACA,kBAAA;EACA,oBAAA;EACA,wBAAA;EACA,uBAAA;AAAF;AAEA;;EAEE,WAAA;EACA,kBAAA;EACA,MAAA;AAAF;AAEA;EACE,YAAA;EACA,uBAAA;AAAF;AAEA;EACE,WAAA;AAAF;AAGA;EACE;;;IAGE,4BAAA;EADF;EAGA;IACE,uBAAA;EADF;AACF","sourcesContent":[".statusText {\n  font-size: 12px;\n  line-height: 32px;\n  margin-left: 10px;\n  color: #748f9e;\n}\n\n.loader,\n.loader:before,\n.loader:after {\n  border-radius: 50%;\n  width: 2.5em;\n  height: 2.5em;\n  animation-fill-mode: both;\n  animation: bblFadInOut 1.8s infinite ease-in-out;\n}\n.loader {\n  color: #661eff;\n  font-size: 7px;\n  position: relative;\n  text-indent: -9999em;\n  transform: translateZ(0);\n  animation-delay: -0.16s;\n}\n.loader:before,\n.loader:after {\n  content: '';\n  position: absolute;\n  top: 0;\n}\n.loader:before {\n  left: -3.5em;\n  animation-delay: -0.32s;\n}\n.loader:after {\n  left: 3.5em;\n}\n\n@keyframes bblFadInOut {\n  0%,\n  80%,\n  100% {\n    box-shadow: 0 2.5em 0 -1.3em;\n  }\n  40% {\n    box-shadow: 0 2.5em 0 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statusText": `WorkerStatusInfo-module__statusText__Qh4sD`,
	"loader": `WorkerStatusInfo-module__loader__A7Rhk`,
	"bblFadInOut": `WorkerStatusInfo-module__bblFadInOut__aKBQc`
};
export default ___CSS_LOADER_EXPORT___;
