// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tag-module__rounded__E33Bq {
  border-radius: 25px;
}
`, "",{"version":3,"sources":["webpack://./src/uiKit/components/general/Tag/Tag.module.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF","sourcesContent":[".rounded {\n  border-radius: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rounded": `Tag-module__rounded__E33Bq`
};
export default ___CSS_LOADER_EXPORT___;
