// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RequestLearningSuggestions-module__learningSuggestions___hQ9o {
  margin-top: 45px;
}
.RequestLearningSuggestions-module__learningSuggestions___hQ9o .ant-collapse-header,
.RequestLearningSuggestions-module__learningSuggestions___hQ9o .ant-collapse-content-box {
  padding: 0 !important;
}
.RequestLearningSuggestions-module__header__Q_7Hj {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  color: #595959;
  font-size: 14px;
  font-weight: 700;
}
.RequestLearningSuggestions-module__title__Fr7ca > .anticon {
  margin-right: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/request/RequestLearningSuggestions.module.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AACE;;EAEE,qBAAA;AACJ;AAGA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;AADF;AAKE;EACE,iBAAA;AAHJ","sourcesContent":[".learningSuggestions {\n  margin-top: 45px;\n\n  & :global(.ant-collapse-header),\n  & :global(.ant-collapse-content-box) {\n    padding: 0 !important;\n  }\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 12px;\n  color: #595959;\n  font-size: 14px;\n  font-weight: 700;\n}\n\n.title {\n  & > :global(.anticon) {\n    margin-right: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"learningSuggestions": `RequestLearningSuggestions-module__learningSuggestions___hQ9o`,
	"header": `RequestLearningSuggestions-module__header__Q_7Hj`,
	"title": `RequestLearningSuggestions-module__title__Fr7ca`
};
export default ___CSS_LOADER_EXPORT___;
